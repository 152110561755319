import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import LeftBg from "../../images/choose-left.png"

import {
  //Triangle,
  LeftGradient,
  RightGradient,
  TopGradient,
  BottomGradient,
  MarkdownContent,
  SectionTitle,
  Container,
} from "../../components/SectionTags"
import TickIcon from "../../components/Icons/TickIcon"
import CtaQuote from "../../components/Icons/CtaQuote"
import CtaPhone from "../../components/Icons/CtaPhone"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const MainPanel = styled.div`
  padding: 80px 0 40px;
  background: #f5f5f5;
`
const Wrap = styled.div`
  position: relative;
  background: #fff;
  display: flex;
  flex-wrap:wrap;
  z-index: 4;
  @media (max-width: 768px) {
    display: block;
  }
`
const TabList = styled.div`
  position: relative;
  width: 100%;
  max-width: 1120px;
  margin: auto;
  margin-bottom: -48px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  z-index: 5;
`
const Tab = styled.div`
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  width: 33.33%;
  text-align: center;
  font-family: 'UniNeueBold', sans-serif;
  border-right: ${(props) => props.br};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  padding: 20px 10px;
  font-size: 13px;
  line-height: 18px;
  ${BreakpointUp.sm`		
		padding: 20px;
		font-size: 16px;
		line-height: 30px;
	`}
  ${BreakpointUp.md`		
    font-size: 18px;
    line-height: 30px;
    flex-direction:row;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
	`}
	${BreakpointUp.lg`		
		font-size: 20px;
		line-height: 30px;
		padding: 30px;
	`}
	.icon {
    display: flex;
    height: 30px;
    align-items: flex-end;
    margin-bottom: 5px;
    ${BreakpointUp.md`	
			align-items:center;
			margin-bottom:0;
		`}
    & svg {
      height: 24px;
    }
    & svg {
      fill:rgba(255, 255, 255, 0.5);
      & path {
        fill:rgba(255, 255, 255, 0.5);
      }
    }
    + .text {
      ${BreakpointUp.md`		
				margin:0 0 0 20px;
			`}
    }
  }
  &:hover{
    background-color:#FAB702;
    color:#fff;
    & svg {
      fill:#fff;
      & path {
        fill:#fff;
      }
    }
  }
`
Tab.defaultProps = {
  br: "2px solid rgba(255, 255, 255, 0.1)",
  bg: "#0059B2",
  color: "rgba(255, 255, 255, 0.5)",
}
const LeftWrap = styled.div`
  position: relative;
  z-index: 2;
  background: #0059b2;
  padding: 60px 30px;
  ${BreakpointUp.lg`		    
    padding: 90px 40px;
    width: 50%;
  `}
  @media (min-width: 1400px) {
    padding: 110px 70px;
    padding-left: calc(50vw - 675px);
  }
`
const RightWrap = styled.div`
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 60px 30px;
  ${BreakpointUp.lg`		   
    padding: 90px 40px;
    width: 50%;
  `}
  @media (min-width: 1400px) {
    padding: 110px 70px 110px 70px;
    padding-right: calc(50vw - 675px);
  }
`

const VerticalTitle = styled.strong`
  position: absolute;
  z-index: 5;
  left:100%;
  transform: rotate(-90deg) translateY(-50%);
  top: 50%;
  color: rgba(0, 89, 178, 0.1);
  margin:0;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  white-space: nowrap;
  font-size: 24px;
  line-height: 32px;
  display: none;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 44px;
  }
  @media (min-width: 1600px) {
    display: block;
    font-size: 44px;
    line-height: 58px;
  }
`

const BackgroundImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.bg});
  background-size: cover;
  background-position: center center;
`

const CustomTriangle = styled.div`
  background: #0852a5;
  &:after {
    top: -200px;
    left: calc(100% + 70px);
    transform: rotate(-135deg);
    background: #0852a5;
  }
  @media (max-width: 1400px) {
    &:after {
      left: 105%;
    }
  }
  @media (max-width: 768px) {
    &:after {
      top: 42px;
      left: 10%;
    }
  }
`

const RepairContent = styled.div`
  position: relative;
  z-index: 2;
  display: ${(props) => props.display};
  & h3 {
    font-size: 28px;
    line-height: 38px;
    color: #000;
    margin-bottom: 13px;
  }
`
const RepairImage = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    margin: auto;
    height: 450px;
  }
  @media (max-width: 480px) {
    .gatsby-image-wrapper {
      height: 280px;
    }
  }
`


const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -10px;
`
const BadgeItem = styled.div`
  padding:10px;
`
const Badge = styled.div`
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 6;  
  cursor: pointer;
  border-radius: 60px;
  padding: 5px 15px;
  font-size:14px;
  font-weight: 600;
  line-height: 24px;
  ${BreakpointUp.lg`
    font-size:16px;
    line-height: 28px;
    padding: 10px 20px;
  `}
  &:hover, &:focus {
    background: #fab702;
  }
`


class RepairSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRepair: this.props.data[0].name,
    }
    this.selectRepair = this.selectRepair.bind(this)
    this.specificationClicked = this.specificationClicked.bind(this)
    this.requestClicked = this.requestClicked.bind(this)
    this.askClicked = this.askClicked.bind(this)
  }
  selectRepair(item) {
    this.setState({
      currentRepair: item,
    })
  }
  specificationClicked() {
    const { specificationCallBack } = this.props
    specificationCallBack()
  }
  requestClicked() {
    const { requestCallBack } = this.props
    requestCallBack()
  }
  askClicked() {
    const { askCallBack } = this.props
    askCallBack()
  }
  render() {
    const { title, data } = this.props
    const { currentRepair } = this.state

    return (
      <div>
        <Container>
          <TabList>
            <Tab onClick={this.specificationClicked}>
              <span className="icon">
                <TickIcon />
              </span>
              <span className="text">AES Specialization</span>
            </Tab>
            <Tab onClick={this.requestClicked}>
              <span className="icon">
                <CtaQuote fill="rgba(255, 255, 255, 0.5)" />
              </span>
              <span className="text">Request a Quote!</span>
            </Tab>
            <Tab br="0" onClick={this.askClicked}>
              <span className="icon">
                <CtaPhone fill="rgba(255, 255, 255, 0.5)" />
              </span>
              <span className="text">Ask An Expert</span>
            </Tab>
          </TabList>
        </Container>
        <MainPanel>
          <Wrap>
            <LeftWrap>
                <BackgroundImg bg={LeftBg} />
                <SectionTitle color="#fff">{title}</SectionTitle>
                <BadgeList>
                  {data.map((item, i) => {
                    return (
                      <BadgeItem>
                        <Badge
                          key={i}
                          bg={item.name === currentRepair ? "#FAB702" : "none"}
                          onClick={() => this.selectRepair(item.name)}
                        >
                          {item.name}
                        </Badge>
                      </BadgeItem>
                    )
                  })}
                </BadgeList>
              <CustomTriangle />
            </LeftWrap>
            <RightWrap>
              <div class="isSticky">
                {data.map((item, i) => {
                  // const temp1 = item.node.service.serviceName.replace(/[^\w\s]+/gi, ' ').replace(/  +/g, ' ');
                  // const serviceUrl = temp1.split(" ").join("-").toLowerCase()
                  // const temp2 = item.node.name.replace(/[^\w\s]+/gi, ' ').replace(/  +/g, ' ');
                  // const repairUrl = temp2.split(" ").join("-").toLowerCase()
                  return (
                    <RepairContent
                      key={i}
                      display={item.name === currentRepair ? "block" : "none"}
                    >
                      <div className="h3">{item.name}</div>
                      <MarkdownContent
                        dangerouslySetInnerHTML={{
                          __html: item.description.childMarkdownRemark.html,
                        }}
                      />
                      <RepairImage>
                        <LeftGradient />
                        <TopGradient />
                        <RightGradient />
                        <BottomGradient />
                        <GatsbyImage image={getImage(item.thumbnail)} alt={item.name} />
                      </RepairImage>
                      <Link to={`/${item.url}`} className="all-link">Explore More <ArrowIcon fill="#FAB702" /></Link>
                      <VerticalTitle>AES Specialization</VerticalTitle>
                    </RepairContent>
                  )
                })}
              </div>
            </RightWrap>
          </Wrap>
        </MainPanel>
      </div>
    )
  }
}

export default RepairSection
