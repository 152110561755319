import React from "react"

const TickIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g id="tick_3_" data-name="tick (3)" transform="translate(0.5 0.5)">
      <g
        id="Group_6997"
        data-name="Group 6997"
        transform="translate(5.698 3.015)"
      >
        <g id="Group_6996" data-name="Group 6996">
          <path
            id="Path_21787"
            data-name="Path 21787"
            d="M139.183,64.593a.9.9,0,0,0-1.279,0L127.053,75.414l-3.915-4.252a.9.9,0,0,0-1.331,1.225l4.553,4.944a.9.9,0,0,0,.646.292h.019a.905.905,0,0,0,.639-.264l11.518-11.487A.9.9,0,0,0,139.183,64.593Z"
            transform="translate(-121.568 -64.327)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="Group_6999" data-name="Group 6999">
        <g id="Group_6998" data-name="Group 6998">
          <path
            id="Path_21788"
            data-name="Path 21788"
            d="M23.1,11.1a.9.9,0,0,0-.9.9A10.191,10.191,0,1,1,12,1.809.9.9,0,1,0,12,0,12,12,0,1,0,24,12,.9.9,0,0,0,23.1,11.1Z"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default TickIcon
