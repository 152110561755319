import React from "react"

const CtaPhone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.157"
    height="31.216"
    viewBox="0 0 31.157 31.216"
  >
    <g id="phone_3_" data-name="phone (3)" transform="translate(0.254 0.616)">
      <g id="Group_6900" data-name="Group 6900" transform="translate(0.344 0)">
        <path
          id="Path_21760"
          data-name="Path 21760"
          d="M24.032,44.255a2.951,2.951,0,0,0-2.14-.981,3.051,3.051,0,0,0-2.165.975l-2,1.994c-.165-.089-.329-.171-.488-.253-.228-.114-.443-.222-.627-.336A21.747,21.747,0,0,1,11.4,40.905a12.829,12.829,0,0,1-1.71-2.7c.519-.475,1-.969,1.469-1.444.177-.177.355-.361.532-.538a2.9,2.9,0,0,0,0-4.382L9.963,30.116c-.2-.2-.4-.4-.589-.6-.38-.393-.779-.8-1.19-1.178a3,3,0,0,0-2.121-.931,3.1,3.1,0,0,0-2.153.931l-.013.013L1.745,30.521A4.632,4.632,0,0,0,.371,33.465a11.1,11.1,0,0,0,.81,4.7,27.262,27.262,0,0,0,4.844,8.079,29.8,29.8,0,0,0,9.922,7.769,15.459,15.459,0,0,0,5.572,1.646c.133.006.272.013.4.013a4.771,4.771,0,0,0,3.653-1.57c.006-.013.019-.019.025-.032A14.392,14.392,0,0,1,26.7,52.923c.272-.26.551-.532.823-.817a3.159,3.159,0,0,0,.956-2.191,3.043,3.043,0,0,0-.975-2.172ZM26.3,50.922c-.006,0-.006.006,0,0-.247.266-.5.507-.772.772A16.65,16.65,0,0,0,24.3,52.961a3.052,3.052,0,0,1-2.381,1.007c-.095,0-.2,0-.291-.006a13.733,13.733,0,0,1-4.939-1.482A28.126,28.126,0,0,1,7.348,45.16a25.705,25.705,0,0,1-4.559-7.592A9.041,9.041,0,0,1,2.08,33.6a2.907,2.907,0,0,1,.874-1.881l2.159-2.159a1.439,1.439,0,0,1,.962-.45A1.354,1.354,0,0,1,7,29.559l.019.019c.386.361.753.734,1.14,1.133.2.2.4.405.6.614l1.729,1.729a1.208,1.208,0,0,1,0,1.963c-.184.184-.361.367-.545.545-.532.545-1.038,1.051-1.589,1.545-.013.013-.025.019-.032.032a1.291,1.291,0,0,0-.329,1.437l.019.057a13.878,13.878,0,0,0,2.045,3.337l.006.006a23.239,23.239,0,0,0,5.623,5.116,8.641,8.641,0,0,0,.779.424c.228.114.443.222.627.336.025.013.051.032.076.044a1.373,1.373,0,0,0,.627.158,1.354,1.354,0,0,0,.962-.437l2.165-2.165a1.432,1.432,0,0,1,.956-.475,1.289,1.289,0,0,1,.912.462l.013.013,3.489,3.489A1.252,1.252,0,0,1,26.3,50.922Z"
          transform="translate(-0.344 -25.671)"
          fill={props.fill}
          stroke={props.fill}
          strokeWidth="1.2"
        />
        <path
          id="Path_21761"
          data-name="Path 21761"
          d="M245.647,87.621a8.149,8.149,0,0,1,6.636,6.636.85.85,0,0,0,.842.709,1.129,1.129,0,0,0,.146-.013.856.856,0,0,0,.7-.988,9.853,9.853,0,0,0-8.029-8.029.859.859,0,0,0-.988.7A.846.846,0,0,0,245.647,87.621Z"
          transform="translate(-229.456 -80.485)"
          fill={props.fill}
          stroke={props.fill}
          strokeWidth="1.2"
        />
        <path
          id="Path_21762"
          data-name="Path 21762"
          d="M262.989,13.234A16.224,16.224,0,0,0,249.768.013.854.854,0,1,0,249.49,1.7,14.492,14.492,0,0,1,261.3,13.512a.85.85,0,0,0,.842.709,1.129,1.129,0,0,0,.146-.013A.839.839,0,0,0,262.989,13.234Z"
          transform="translate(-233.045 0)"
          fill={props.fill}
          stroke={props.fill}
          strokeWidth="1.2"
        />
      </g>
    </g>
  </svg>
)
CtaPhone.defaultProps = {
  fill: "#fff",
}
export default CtaPhone
