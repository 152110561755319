import styled from "styled-components"

const Mask = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 8px;
  background: ${(props) => props.bg};
`
Mask.defaultProps = {
  bg: "radial-gradient(rgba(0, 89, 178, 0.1), rgba(0, 89, 178, 0.7))",
}

export default Mask
