import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import BreakpointUp from "../Media/BreakpointUp"
import { Section, Container, SectionTitle } from "../SectionTags"
import Industry from "../Industry"

const Carousel = styled.div`
  ${BreakpointUp.xxl`		
    padding-left: calc((100% - 1350px) / 2); 
	`}
`

const CarouselItem = styled.div`
  position: relative;
  padding: 10px;
  
`
// const Triangle = styled.div`
//   position: absolute;
//   width: 50px;
//   height: 50px;
//   background: #f5fbff;
//   bottom: -30px;
//   left: calc(50% - 25px);
//   transform: rotate(45deg);
//   display: ${(props) => props.display};
// `

const CardIndustry = styled.div`
  background-color:#fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 8px;
`
const CardIndustryFigure = styled.div`  
  position: relative;
  & .gatsby-image-wrapper {
    width:100%;
    border-radius: 8px 8px 0 0;
  }
`
const CardIndustryCaption = styled.div`
  position: relative;  
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: ${(props) => props.bg};
  color: ${(props) => props.color}; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  @media(min-width:576px){
    min-height: 104px;
  }
  ${BreakpointUp.xxl`		
    padding: 20px;
	`}
  &:hover {
    background: #fab702;
    color: #fff;
  }
`
const CardText = styled.div`
  font-weight:700;
`

class IndustriesSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisbleIndustry: false,
      currentIndustry: "",
    }
    this.openIndustry = this.openIndustry.bind(this)
    this.closeIndustry = this.closeIndustry.bind(this)
  }
  openIndustry(data) {
    this.setState({
      currentIndustry: data,
      isVisbleIndustry: true,
    })
  }
  closeIndustry() {
    this.setState({
      isVisbleIndustry: false,
    })
  }
  render() {
    const { title, data } = this.props
    const { isVisbleIndustry, currentIndustry } = this.state
    const settings = {
      dots: false,
      swipeToSlide: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          },
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1
          },
        },
      ],
    }

    return (
      <>
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px" bgColor="#f5f5f5">
          <Carousel>
            <Container maxWidth="100%">
              <SectionTitle>{title}</SectionTitle>
              <Slider {...settings}>
                {data.edges.map((item, i) => {
                  return (
                    <CarouselItem
                      key={i}
                      onClick={() => this.openIndustry(item.node)}
                    >
                      <CardIndustry>
                        <CardIndustryFigure>
                          {item.node.thumbnail?.gatsbyImageData && (
                            <GatsbyImage
                              image={item.node.thumbnail.gatsbyImageData}
                              alt={item.node.name}
                            />
                          )}
                        </CardIndustryFigure>
                        <CardIndustryCaption
                        bg={
                          isVisbleIndustry &&
                          currentIndustry.name === item.node.name
                            ? "#FAB702"
                            : "white"
                        }
                        color={
                          isVisbleIndustry &&
                          currentIndustry.name === item.node.name
                            ? "white"
                            : "black"
                        }
                        >
                          <CardText>{item.node.name}</CardText>
                        </CardIndustryCaption>
                          {/* <Triangle
                            display={
                              isVisbleIndustry &&
                              currentIndustry.name === item.node.name
                                ? "block"
                                : "none"
                            }
                          /> */}
                      </CardIndustry>
                    </CarouselItem>
                  )
                })}
              </Slider>
            </Container>
          </Carousel>
        </Section>
        <Industry
          isOpen={isVisbleIndustry}
          data={currentIndustry}
          onClose={this.closeIndustry}
        />
      </>
    )
  }
}

export default IndustriesSection
