import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import scrollToRef from "../components/scrollToRef"
import Header from "../components/Header"
import {
  Section,
  BreadCrumb,
  Container,
  SectionPageTitle,
  MarkdownContent,
} from "../components/SectionTags"
import Mask from "../components/Mask"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import AddressCTA from "../components/CallToAction/AddressCTA"
import IndustriesCarousel from "../components/IndustriesCarousel"
import Content from "../components/Content"
import LatestArticleVideo from "../components/LatestArticleVideo"
import SearchCTA from "../components/CallToAction/SearchCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

import RepairSection from "../sections/service/RepairSection"
import ServiceContact from "../sections/service/ServiceContact"
import AboutPartnerBrand from "../sections/about/AboutPartnerBrand"
import LeftImg from "../images/service-hero-left-bg.svg"
import RightImg from "../images/service-hero-right-bg.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const LeftBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  z-index: 1;
  display: none;
  ${BreakpointUp.lg`	  
    display: block;
  `}
`
const RightBg = styled.div`
  position: absolute;
  top: 0;
  right:0;
  width: 50%;
  height: 100%;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  z-index: 1;
  display: none;
  ${BreakpointUp.lg`	  
    display: block;
  `}
`
const HeroContent = styled.div`
  position: relative;
  max-width: 1120px;
  width: 100%;
  margin: auto;
  z-index: 2;
  h1 {
    width: 100%;
    text-align: center;
    color: white;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );     */
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-bottom: 0;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 40px 0;
  }
`
const HeroImage = styled.div`
  position: relative;
  z-index: 4;
  width: 100%;
  border-radius: 0 0 8px 8px;
  .gatsby-image-wrapper {
    width: 100%;
    height: 480px;
    border-radius: 0 0 8px 8px;
  }
  @media (max-width: 1200px) {
    .gatsby-image-wrapper {
      height: 400px;
    }
  }
  @media (max-width: 1024px) {
    .gatsby-image-wrapper {
      height: 350px;
    }
  }
  @media (max-width: 480px) {
    .gatsby-image-wrapper {
      height: 260px;
    }
  }
`
const Description = styled.div`
  margin: 0 auto;
  max-width: 1050px;
  width: 100%;
  text-align: center;
  background: white;
  padding: 15px 0;
  ${BreakpointUp.sm`
	  padding: 30px 20px 30px;
  `}
  ${BreakpointUp.md`
		padding: 40px 30px 60px;
	`}
	${BreakpointUp.xl`
		padding: 40px 30px 60px;
	`}
`

class ServiceTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulService
    const emergencyCtaData = pageData.emergencyCta
    const estimateCtaData = pageData.estimateCta
    const repairSectionTitle = pageData.repairsSectionTitle
    const repairSectionData = pageData.service_sub_repair
    const serviceContactData = pageData.serviceContactForm
    const addressCtaData = pageData.addressCta
    const industrySectionTitle = pageData.industrySectionTitle
    const industries = this.props.data.allContentfulIndustry
    const partnerSectionTitle = pageData.partnerSectionTitle
    const AllPartners = this.props.data.allContentfulPartner
    const AllBrands = this.props.data.allContentfulBrand
    const searchCtaTitle = pageData.searchSectionTitle
    const htmlData = pageData.content
    const contentVideo = pageData.contentVideoUrl
    const articles = this.props.data.allContentfulArticle
    const videos = this.props.data.allContentfulVideo

    const specificationRef = React.createRef()
    const specificationNav = () => {
      scrollToRef(specificationRef)
    }
    const requestQuoteRef = React.createRef()
    const requestQuoteNav = () => {
      scrollToRef(requestQuoteRef)
    }
    const askRef = React.createRef()
    const askNav = () => {
      scrollToRef(askRef)
    }
    return (
      <Layout>
        <Seo title={pageData.metaTitle}
          description={pageData.metaDescription.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px">
          <LeftBg bg={LeftImg} />
          <RightBg bg={RightImg} />
          <Container>
            <HeroContent>
              <HeroImage>
                <BreadCrumb className="white" position="absolute" mt="20px" ml="20px">
                  <Link to="/"><span>Home</span></Link> {pageData.serviceName}
                </BreadCrumb>
                <GatsbyImage image={getImage(pageData.heroImage)} alt={pageData.serviceName} />
                <Mask bg="radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3))" />
                <SectionPageTitle>{pageData.serviceName}</SectionPageTitle>
              </HeroImage>
              <Description>
                <MarkdownContent textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </Description>
            </HeroContent>
          </Container>
        </Section>        
        <div style={{ marginBottom: "-70px" }} ref={specificationRef} />
        <RepairSection
          title={repairSectionTitle}
          data={repairSectionData}
          specificationCallBack={specificationNav}
          requestCallBack={requestQuoteNav}
          askCallBack={askNav}
        />
        <SearchCTA title={searchCtaTitle} serviceName={pageData.serviceName} />
        <div ref={requestQuoteRef} />
        <ServiceContact data={serviceContactData} />
        <div ref={askRef} />
        <AddressCTA data={addressCtaData} />
        <IndustriesCarousel title={industrySectionTitle} data={industries} />
        <AboutPartnerBrand
          title={partnerSectionTitle}
          partners={AllPartners}
          brands={AllBrands}
          bg="#EDF8FF"
        />
        
        {htmlData && <Content data={htmlData} video={contentVideo} />}
        <LatestArticleVideo
          title={pageData.latestSectionTitle}
          articles={articles}
          videos={videos}
        />
        <EmergencyEstimateCTA
          leftData={emergencyCtaData}
          rightData={estimateCtaData}
        />
      </Layout>
    )
  }
}
export default ServiceTemplate

export const pageQuery = graphql`
  query servicePageQuery($id: String!) {
    contentfulService(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      serviceName
      heroImage {
        id
        gatsbyImageData(quality: 100)
      }
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      repairsSectionTitle
      service_sub_repair {
        url
        name
        thumbnail {
          gatsbyImageData(quality: 100)
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      serviceContactForm {
        title
        secondTitle
        image {
          gatsbyImageData(quality: 100)
        }
      }
      addressCta {
        title
        image {
          gatsbyImageData(quality: 100)
        }
        linkText
        label
        features {
          address
          phone
          email
        }
      }
      industrySectionTitle
      partnerSectionTitle
      searchSectionTitle
      content {
        childMarkdownRemark {
          html
        }
      }
      contentVideoUrl
      latestSectionTitle
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
    allContentfulIndustry(sort: { fields: order }) {
      edges {
        node {
          url
          name
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
          features {
            content
          }
        }
      }
    }
    allContentfulPartner(sort: { fields: order }) {
      edges {
        node {
          logo {
            gatsbyImageData(quality: 100)
          }
          url
          name
          logoWidth
        }
      }
    }
    allContentfulBrand(sort: { fields: order }) {
      edges {
        node {
          image {
            gatsbyImageData(quality: 100)
          }
          url
          title
          imageWidth
        }
      }
    }
    allContentfulArticle(sort: { fields: postDate, order: DESC }, limit: 1) {
      edges {
        node {
          url
          title
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          authorName
          authorRole
          authorImage {
            gatsbyImageData(quality: 100)
          }
          postDate(fromNow: true)
        }
      }
    }
    allContentfulVideo {
      edges {
        node {
          title
          videoUrl
        }
      }
    }
  }
`
