import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import { SectionTitle } from "../../components/SectionTags"
// import { FormGroup } from "../../components/SectionTags"
// import PrimaryButton from "../../components/Button/PrimaryButton"
// import { navigate } from "gatsby-link"
// import encode from "../../utils/encode"

const Wrap = styled.div`
  position: relative;
  display: flex;
  background: #fff;
  padding: 40px 0 0;
  ${BreakpointUp.md`
		padding:60px 0 0;
	`}
  ${BreakpointUp.xl`
		padding: 100px 0 0;
	`}
`
const LeftWrap = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
  padding: 0 20px;
  min-height: 700px;
  iframe {
    border: 0;
    width: 100%;
    height: 920px;
  }
  @media(min-width: 440px) {
    iframe {
      border: 0;
      width: 100%;
      height: 885px;
    }
  }
  @media(min-width: 440px) {
    iframe {
      border: 0;
      width: 100%;
      height: 710px;
    }
  }
  @media(min-width: 656px) {
    iframe {
      border: 0;
      width: 100%;
      height: 650px;
    }
  }
  @media(min-width: 768px) {
    width: 70%;

    iframe {
      height: 720px;
    }
  }
  @media(min-width: 938px) {
    width: 70%;

    iframe {
      height: 650px;
    }
  }
  ${BreakpointUp.lg`
		width: calc(50% + 145px);
		margin-right: -145px;
		padding-right:0;
	`}
	@media (min-width: 1440px) {
    padding-left: calc(50vw - 675px);
  }
`
const RightWrap = styled.div`
  position: relative;
  ${BreakpointUp.md`
		width: 30%;
	`}
  ${BreakpointUp.lg`
		width: calc(50% + 145px);		
		margin-left: -145px;
	`}
	.gatsby-image-wrapper {
    height: 100%;
  }
  ${BreakpointDown.md`
		display: none;		
	`}
`

export const LeftGradient = styled.div`
  position: absolute;
  z-index: 3;
  width: 50%;
  left: -1px;
  top: 0;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0)
  );
`
const TopGradient = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  left: 0;
  top: -1px;
  height: 30%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0)
  );
`

class ServiceContact extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <LeftWrap>          
          <iframe src="https://www.cognitoforms.com/f/7qxSu5WtzEeoHIw8Fz5hfw/4" title="Cognito Forms"></iframe>
        </LeftWrap>
        <RightWrap>
          <LeftGradient width="50%" />
          <TopGradient height="25%" />
          <GatsbyImage image={getImage(data.image)} alt="AES" />
        </RightWrap>
      </Wrap>
    )
  }
}

export default ServiceContact
