import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import BreakpointUp from "../Media/BreakpointUp"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import ArrowIcon from "../Icons/ArrowIcon"
import CloseIcon from "../Icons/CloseIcon"

const Wrap = styled.div`
  position: relative;
  display: ${(props) => props.display};
  background: #f5fbff;
  padding: 40px 0;
  ${BreakpointUp.md`
		padding:60px 0;		
	`}
  ${BreakpointUp.xl`
		padding:100px 0 60px;
	`}
	${BreakpointUp.xxl`
		padding:100px 0;
	`}
`
const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.xl`	
		margin: 0 -15px;
	`}
  ${BreakpointUp.xxl`	
		margin:0 -50px;
	`}
`
const TextWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  ${BreakpointUp.lg`	
		max-width: 50%;
		flex: 0 0 50%;
		padding:0 15px;
	`}
  ${BreakpointUp.xl`	
		padding:0 30px;
	`}
	${BreakpointUp.xxl`	
		padding:0 50px;
	`}
	& p {
    margin-bottom: 15px;
  }
  & a {
    font-size: 16px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    line-height: 22px;
    color: #fab702;
    & svg {
      margin: 0 0 0 5px;
      transition: transform ease 0.2s;
    }
    &:hover {
      & svg {
        transform: translateX(5px);
      }
    }
  }
`
const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  ${BreakpointUp.lg`	
		max-width: 50%;
		flex: 0 0 50%;
		padding:0 15px;
	`}
  ${BreakpointUp.xl`	
		padding:0 30px;
	`}
	${BreakpointUp.xxl`	
		padding:0 50px;
	`}
`
const FeatureWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  ${BreakpointUp.xl`	
		margin-bottom: 50px;
	`}
  ${BreakpointUp.xxl`	
		margin-bottom: 60px;
	`}
`
const Feature = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  font-weight: 600;
  color: #000000;
  background: #ffffff;
  border: 1px solid #c3e3ff;
  box-shadow: 0 15px 25px rgba(28, 141, 255, 0.06);
  border-radius: 50px;
  padding: 6px 20px;
  font-size: 16px;
  line-height: 34px;
  ${BreakpointUp.xxl`
		padding: 13px 25px;		
	`}
  &:hover {
    cursor: pointer;
    background: #fab702;
    color: white;
    border: 1px solid #fab702;
    box-shadow: none;
  }
`

const Close = styled.div`
  position: absolute;
  width:32px;
  height:32px;
  background-color:#0059B2;
  text-align:center;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:50%;
  top: -30px;
  right: 20px;
  cursor: pointer;
  ${BreakpointUp.xl`
    top: -60px;
    right: 20px;
	`}
  ${BreakpointUp.xxl`
		top: -60px;
		right: -20px;
	`}
	& svg {
    width: 14px;
    height: 14px;
  }
  &:hover {
    background-color:#FAB702;
  }
`
class Industry extends React.Component {
  constructor(props) {
    super(props)
    this.closeIndustry = this.closeIndustry.bind(this)
  }
  closeIndustry() {
    const { onClose } = this.props
    onClose()
  }
  render() {
    const { isOpen, data, onClose } = this.props
    if (data !== "") {
      // 	const temp = data.name.replace(/[^\w\s]+/gi, ' ').replace(/  +/g, ' ');
      // const titleUrl = temp.split(" ").join("-").toLowerCase()
      return (
        <Wrap display={isOpen ? "block" : "none"}>
          <div className="container">
            <Close onClick={this.closeIndustry}>
              <CloseIcon fill="#fff" />
            </Close>
            <ContentWrap>
              <TextWrap>
                <h3>{data.name}</h3>
                <p>{data.description.childMarkdownRemark.excerpt}</p>
                <FeatureWrap>
                  {data.features.map((item, i) => {
                    return <Feature key={i}>{item.content}</Feature>
                  })}
                </FeatureWrap>
                <Link to={`/${data.url}`}>
                  <PrimaryLinkButton
                    text="See More Details"
                    icon={<ArrowIcon />}
                  />
                </Link>
              </TextWrap>
              <ImageWrap>
                <GatsbyImage image={getImage(data.image)} alt="AES" />
              </ImageWrap>
            </ContentWrap>
          </div>
        </Wrap>
      )
    } else {
      return true
    }
  }
}

export default Industry
