import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ArrowIcon from "../Icons/ArrowIcon"
import DotIcon from "../Icons/DotIcon"
import VideoBlock from "../VideoBlock"
import MVVideoModal from "../MVVideoModal"
import {Section, Container, SectionTitle} from "../SectionTags"
import BreakpointUp from "../Media/BreakpointUp"

const ArticleVideo = styled.div`  
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const ArticleVideoItem = styled.div`  
  position:relative;
  width:100%;
  padding:0 15px;
  margin-bottom:60px;
  ${BreakpointUp.lg`
    flex:0 0 50%;
    max-width:50%;
  `}
`
const ArticleWrap = styled.div`
  position: relative;
  height:100%;
`
const Article = styled.div`
  position: relative;
  overflow:hidden;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 30px;
  height:calc(100% - 30px);
  & label {
    position: absolute;
    top:20px;
    left:20px;
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    background: black;
    font-size: 12px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    line-height: 20px;
    z-index: 2;
  }
  & .gatsby-image-wrapper {
    height:100%;
    width: 100%;
  }
`
const CardBottom = styled.div`
  position: absolute;
  left:0;
  right:0;
  bottom: 0px;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));  
  z-index: 3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: flex-end;
`
const Description = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: white;
  width: calc(100% - 200px);
  @media (max-width: 1024px) {
    display: none;
    width: calc(100% - 200px);
  }
`
const WriterWrap = styled.div`
  position: relative;
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  margin-left: ${(props) => props.ml};
  @media (max-width: 1024px) {
    width: 100%;
  }
`
WriterWrap.defaultProps = {
  display: "block",
  ml: "0",
  width: "200px",
}
const Avatar = styled.div`
  position: relative;
  height: 50px;
  text-align: right;
  margin-bottom: 5px;
  & .gatsby-image-wrapper {
    position: relative !important;
    float: right;
    object-fit: contain;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #fab702;
  }
`
const Writer = styled.div`
  text-align: right;
  & strong,
  & span {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    display: block;
  }
  & strong {
    font-size: 16px;
    line-height: 22px;
    color: #fab702;
  }
  & span {
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    color: #666666;
    & svg {
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
`
const VideoWrap = styled.div`
  position: relative;
  margin-bottom: 30px;
  height:calc(100% - 30px);
  overflow:hidden;
  border-radius: 8px;
`
const VideoBottom = styled.div`
  position: absolute;
  bottom: 0;
  left:0;
  right:0;
  padding: 20px;  
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  z-index: 3;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

class LatestArticleVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
      currentVideo: { src: "", type: "" },
    }
    this.handleVideo = this.handleVideo.bind(this)
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  playVideo() {
    this.refs.vidRef.play()
  }
  openVideoModal(url) {
    this.setState({
      currentVideo: {
        type: "video/youtube",
        src: url,
      },
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  handleVideo(url) {
    this.openVideoModal(url)
  }
  render() {
    const { title, articles, videos } = this.props
    return (
      <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="60px" pb="60px" bgColor="#edf8ff">
        <Container>
          <MVVideoModal
            isOpen={this.state.isVideoModalOpen}
            autoPlay
            src={this.state.currentVideo.src}
            type={this.state.currentVideo.type}
            controls
            onClose={this.closeVideoModal}
          />
          <SectionTitle mb="30px">{title}</SectionTitle>
          <ArticleVideo>
            <ArticleVideoItem>
              <ArticleWrap>
                <Article>
                  <Link to={`/${articles.edges[0].node.url}`}>
                    <label>Featured</label>
                    <GatsbyImage
                      image={getImage(articles.edges[0].node.thumbnail)}
                      alt="AES"
                    />
                    <CardBottom>
                      <Description>{articles.edges[0].node.title}</Description>
                      <WriterWrap>
                        <Avatar>
                          <GatsbyImage
                            image={getImage(articles.edges[0].node.authorImage)}
                            alt="AES"
                          />
                        </Avatar>
                        <Writer>
                          <strong>{articles.edges[0].node.authorName}</strong>
                          <span>
                            {articles.edges[0].node.authorRole} <DotIcon />{" "}
                            {articles.edges[0].node.postDate}
                          </span>
                        </Writer>
                      </WriterWrap>
                    </CardBottom>
                  </Link>
                </Article>
              </ArticleWrap>
              <Link className="all-link" to="/articles">
                Explore All Articles <ArrowIcon fill="#FAB702" />
              </Link>
            </ArticleVideoItem>
            <ArticleVideoItem>
              <VideoWrap>
                <VideoBlock
                  action={() => this.handleVideo(videos.edges[0].node.videoUrl)}
                  video={videos.edges[0].node.videoUrl}
                />
                <VideoBottom>
                  <Description>{videos.edges[0].node.title}</Description>
                </VideoBottom>
              </VideoWrap>
              <Link className="all-link" to="/videos">
                Explore All Videos <ArrowIcon fill="#FAB702" />
              </Link>
            </ArticleVideoItem>
          </ArticleVideo>
        </Container>
      </Section>
    )
  }
}

export default LatestArticleVideo
