import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import CirclePhone from "../Icons/CirclePhone"
import { SectionTitle } from "../SectionTags"
import PrimaryButton from "../Button/PrimaryButton"
import BreakpointUp from "../Media/BreakpointUp"

import AddressIcon from "../Icons/AddressIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import EmailIcon from "../Icons/EmailIcon"
import ItemHover from "../ItemHover"
// import AddressPointer from "../Icons/AddressPointer"

const Container = styled.div`
  position: relative;
`
const Wrap = styled.div`
  position: relative;
  z-index: 10;
  height: 100%;
  background: radial-gradient(
    circle at 20%,
    rgba(255, 255, 255, 0.001),
    rgba(0, 89, 178, 0.5),
    rgba(0, 89, 178, 0.8) 50%,
    rgba(0, 89, 178, 1)
  );
  padding: 40px 0;
  ${BreakpointUp.md`
		padding:60px 0;
	`}
  ${BreakpointUp.xl`
		padding: 100px 0;
	`}
	${BreakpointUp.xxl`
		padding: 160px 0;
	`}
	
	@media(max-width: 480px) {
    background: radial-gradient(
      circle at 30%,
      rgba(255, 255, 255, 0.001),
      rgba(0, 89, 178, 0.5),
      rgba(0, 89, 178, 0.8) 70%,
      rgba(0, 89, 178, 1)
    );
  }
  h2 {
    color: #fff;
  }
`
const Address = styled.div`
  cursor: pointer;
  max-width: 200px;
  width: 100%;
  ${BreakpointUp.md`
		position: absolute;
		z-index: 20;
		transform: translate(-50%,-25%);
		top: 25%;
		left: 50%;
	`}
  ${BreakpointUp.xl`
		max-width: 220px;
	`}
	${BreakpointUp.xxl`
		top: 25%;
		left: 25%;
		transform: translate(-25%,-25%);
	`}
`
// const MapPointer = styled.div`
//   max-width: 230px;
//   width: 100%;
//   color: white;
//   font-family: 'UniNeueBold', sans-serif;
//   font-weight: 700;
//   display: flex;
//   align-items: center;
//   flex-wrap: nowrap;
//   padding: 15px 0;

//   .icon {
//     + .text {
//       margin-left: 15px;
//     }
//   }
//   .text {
//     font-size: 14px;
//     line-height: 20px;
//     ${BreakpointUp.xl`
// 			font-size:16px;
// 			line-height:24px;
// 		`}
//   }
// `
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const DetailInfo = styled.div`
  width: 280px;
  padding: 30px;
  background: white;
  border-radius: 8px;
  display: block;
  ${BreakpointUp.sm`
    width: 330px;
    padding: 40px 60px 40px 30px;
  `}
  ${BreakpointUp.md`
		display: ${(props) => props.display};
		position: absolute;
		top:75px;
		left: -80px;
	`}
`
const AddressItem = styled.div`
  margin: 15px 0;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 600;
  & u {
    color: #0059b2;
    &:hover {
      text-decoration: none;
    }
  }
  & a {
    color: #000;
    &:hover {
      color: #0059b2;
    }
  }
  .icon {
    svg {
      width: 20px;
    }
    + .text {
      margin-left: 20px;
    }
  }
`
const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: flex-end;
  flex-direction: column-reverse;
  ${BreakpointUp.md`	
		flex-direction: row;
	`}
`
const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`	
		flex: 0 0 50%;
		max-width: 50%;
	`}
`
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`	
		flex: 0 0 50%;
		max-width: 50%;
	`}
  .btn {
    .icon {
      & svg {
        width: 24px;
        ${BreakpointUp.xxl`
					width: 36px;
				`}
      }
    }
    ${BreakpointUp.xxl`
			padding: 28px 40px !important;
			font-size: 32px;
			line-height: 48px;
			& svg{
				width: 38px;
    			height: 38px
			}
		`}
  }
`
const SectionBtnTool = styled.div`
  margin-bottom: 30px;
  ${BreakpointUp.md`
		margin-bottom:0;
	`}
`

class AddressCTA extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Container>
        <Background>
          <GatsbyImage image={getImage(data.image)} alt="AES" />
        </Background>

        <Wrap>
          <div className="container">
            <MainPanel>
              <LeftPanel>
                <ItemHover>
                  {(hoverItem) => (
                    <Address>
                      {/* <MapPointer>
                        <span className="icon">
                          <AddressPointer />
                        </span>
                        <span className="text">{data.label}</span>
                      </MapPointer> */}
                      <DetailInfo display={hoverItem ? "block" : "none"}>
                        {/* <Triangle
                          bg="white"
                          top="-40px"
                          left="75px"
                          mtop="-30px"
                        /> */}
                        <AddressItem>
                          <span className="icon">
                            <AddressIcon />
                          </span>
                          <span className="text">
                            {data.features[0].address}
                          </span>
                        </AddressItem>
                        <AddressItem>
                          <span className="icon">
                            <PhoneIcon />
                          </span>
                          <span className="text">
                            <a href="tel:8663861001">
                              {data.features[0].phone}
                            </a>
                          </span>
                        </AddressItem>
                        <AddressItem>
                          <span className="icon">
                            <EmailIcon />
                          </span>
                          <span className="text">
                            <a href="mailto:sales@aesintl.com">
                              <u>{data.features[0].email}</u>
                            </a>
                          </span>
                        </AddressItem>
                      </DetailInfo>
                    </Address>
                  )}
                </ItemHover>
              </LeftPanel>
              <RightPanel>
                <SectionTitle>{data.title}</SectionTitle>
                <SectionBtnTool>
                  <a href="tel:8663861001">
                    <button type="submit">
                      <PrimaryButton
                        textAfter={data.linkText}
                        icon={<CirclePhone />}
                        size="lg"
                      />
                    </button>
                  </a>
                </SectionBtnTool>
              </RightPanel>
            </MainPanel>
          </div>
        </Wrap>
      </Container>
    )
  }
}

export default AddressCTA
